<template>
  <div class="blog-wrapper p-1">
    <MenuComponent />
    <b-link href="/">
      <b-col cols="12" class="mt-3 text-center">
        <h2>Dapatkan Produk Terbaru Kami</h2>
        <b-card-text class="mb-3"> </b-card-text>
      </b-col>
    </b-link>
    <b-row>
      <b-col sm="1"></b-col>
      <b-col sm="10">
        <section class="itemView">
          <b-row class="match-height">
            <b-col
              md="3"
              v-for="product in dataProduct"
              v-bind:key="product.id"
            >
              <b-card class="ecommerce-card" no-body>
                <div class="item-img text-center">
                  <b-link :href="'/product/detail/' + product.id">
                    <b-img
                      fluid
                      class="card-img-top"
                      :src="getUrl + 'penyimpanan/product/' + product.photo"
                      :style="{ width: '180px', height: '270px' }"
                    />
                  </b-link>
                </div>

                <!-- Product Details -->
                <b-card-body>
                  <div class="item-wrapper">
                    <div class="item-rating">
                      <ul class="unstyled-list list-inline">
                        <li class="ratings-list-item star-1">
                          <feather-icon
                            icon="StarIcon"
                            size="16"
                            class="fill-current text-warning"
                          />
                        </li>
                        <li class="ratings-list-item star-1">
                          <feather-icon
                            icon="StarIcon"
                            size="16"
                            class="fill-current text-warning"
                          />
                        </li>
                        <li class="ratings-list-item star-1">
                          <feather-icon
                            icon="StarIcon"
                            size="16"
                            class="fill-current text-warning"
                          />
                        </li>
                        <li class="ratings-list-item star-1">
                          <feather-icon
                            icon="StarIcon"
                            size="16"
                            class="fill-current text-warning"
                          />
                        </li>
                        <li class="ratings-list-item star-1">
                          <feather-icon
                            icon="StarIcon"
                            size="16"
                            class="fill-current text-warning"
                          />
                        </li>
                      </ul>
                    </div>
                    <!-- <div>
                  <h6 class="item-price">harga</h6>
                </div> -->
                    <div class="item-wrapper">
                      <div class="item-cost">
                        <h4 class="item-price">
                          Rp. {{ formatPrice(product.price) }}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <h6 class="item-name">
                    <b-link class="text-body"> {{ product.title }} </b-link>
                    <b-card-text class="item-company">
                      oleh
                      <b-link class="ml-25"> {{ product.creator }} </b-link>
                    </b-card-text>
                  </h6>
                  <!-- <b-card-text class="item-description"> {{ product.description }} </b-card-text> -->
                </b-card-body>

                <!-- Product Actions -->
                <div class="item-options text-center">
                 
                  <b-link :href="'/product/detail/' + product.id">
                    <b-button
                      variant="primary"
                      class="btn-wishlist"
                      style="width: 50%"
                    >
                      <feather-icon icon="ShoppingCartIcon" class="mr-50" />
                      <span> Beli</span>
                    </b-button>
                  </b-link>
                  <b-link :href="'/product/detail/' + product.id">
                    <b-button
                      variant="warning"
                      class="btn-cart"
                      style="width: 50%"
                    >
                      <feather-icon icon="SearchIcon" class="mr-50" />
                      <span> Detail</span>
                    </b-button>
                  </b-link>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </section>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
} from "bootstrap-vue";
import ContentWithSidebar from "@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue";
import MenuComponent from "@/views/Menu.vue";
export default {
  components: {
    BRow,
    BCol,
    MenuComponent,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
    ContentWithSidebar,
  },
  data() {
    return {
      search_query: "",
      currentPage: 1,
      perPage: 1,
      rows: 50,
      dataProduct: [],
      level: localStorage.getItem("Ulevel"),
      selected: "left",
      selected2: "right",
      getUrl: "",
    };
  },
  async mounted() {
    await this.getData();
    this.getUrl = process.env.VUE_APP_URL;
    // console.log("cek", process.env.VUE_APP_URL)
  },
  methods: {
    async getData() {
      await this.$http
        .get(process.env.VUE_APP_BACKEND_URL + "products/view")
        .then((res) => {
          this.dataProduct = res.data.data;
        });
    },
    formatPrice(value) {
      if (value != null) {
        const val = Number(value).toFixed(2).replace(/\.00$/, "");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
